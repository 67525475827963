@use 'style/reboot';
@use 'style/text';
@use 'style/buttons';
@use 'style/badge';
@use 'style/popover';
@use 'style/toast';
@use 'style/scroll';
@use 'style/rqc/rqc';
@use 'style/shortcuts';
@use 'style/display';

:root {
  @include rqc.build-rqc();
  @include shortcuts.setup();
  @include text.setup();
  @include buttons.setup();
  @include toast.setup();
  @include popover.setup();
  @include scroll.setup();
  @include badge.setup();
  @include display.setup();

  --max-content-width: 1920px;
  --header-height: 60px;
  --footer-height: 32px;
  --default-sidebar--expanded: 280px;
  --default-sidebar--collapsed: 60px;
  --sidebar-width: var(--default-sidebar--expanded);
  --sidebar-footer-height: 48px;
  --page-header-height: 90px;
}

body {
  overflow: hidden;
  background-color: var(--background-primary);
  color: var(--text-secondary);
  font-size: var(--default-font-size);
}

.page-full-h {
  min-height: calc(100vh - var(--header-height) - var(--page-header-height) - var(--spacer-xxl)) !important;
  margin-bottom: var(--spacer-xxl);
}

.page-header {
  height: var(--page-header-height);
  padding: 0;
  display: flex;
  align-items: center;
  gap: var(--spacer-sm);
}

.full-h {
  display: flex !important;
  flex-direction: column !important;
  flex-grow: 1 !important;
}

.fixed-container {
  .layout-wrapper-simple {
    overflow: auto;
    scrollbar-gutter: stable;
    height: calc(100vh - var(--header-height) - var(--footer-height) - var(--spacer-sm));
    position: relative;
    width: 100%;
    max-width: 100%;
  }

  .layout-wrapper-with-sidebar {
    @extend .layout-wrapper-simple;

    height: calc(100vh - var(--header-height));
    padding-left: calc(var(--sidebar-width) + var(--spacer-md));

    .sidebar-navigation {
      position: fixed;
      left: 0;
      top: calc(var(--header-height));
      height: calc(100vh - var(--header-height));
      z-index: var(--z-index-xs);
    }
  }

  .boxed-wrapper {
    max-width: var(--max-content-width) !important;
    width: 100% !important;
    min-width: auto !important;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1680px) {
      padding-left: var(--spacer-md) !important;
      padding-right: var(--spacer-md) !important;
    }
  }
}